import { createApp } from 'vue'
import App from './App.vue'
import Keycloak from "keycloak-js"
import {apiAuthorizeUrl} from "@/config/api"
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

let initOptions = {
    url: apiAuthorizeUrl, realm: 'axesor', clientId: 'cronjob-r-front', onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions)

keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
        if (!auth) {
            window.location.reload()
        } else
            createApp(App,{keycloak: keycloak})
                .use(Antd)
                .mount('#app')

        setInterval(() => {
            keycloak
                .updateToken(70)
                .catch(() => {
                    console.log('Failed to refresh token')
                })
        }, 6000)

    }).catch((error) => {
        console.log(error)
        console.log("Authenticated Failed")
    })
