<template>
  <div id="app">
    <h1>Cronjob R</h1>

    <a-button class="button" type="primary" @click="insertEvent">
      Insert
    </a-button>
    <a-button class="button" type="primary" @click="refreshEvents">
      Refresh
    </a-button>
    <a-button id="logout" type="dashed" @click="logout">
      Logout
    </a-button>

    <ag-grid-vue
        id="table"
        style=" height: 700px"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="events"
        :gridOptions="gridOptions"
        :getRowStyle="getRowStyle"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="modalVisible"
        :closable=false
        title="Event"
        :okText="formEventAction"
        @ok="okModal">
      <a-form
          v-model="formEvent"
          name="form_event"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item name="time" label="Time" v-bind="config">
          <a-date-picker
              v-model:value="formEvent['time']"
              show-time
              format="DD/MM/YYYY HH:mm"
              value-format="DD/MM/YYYY HH:mm"
          />
        </a-form-item>
        <a-form-item name="action" label="Action">
          <a-select
              v-model:value="formEvent['action']"
              style="width: 200px"
              :options="actionOptions"
          ></a-select>
        </a-form-item>
        <a-form-item name="date" label="Date execution">
          <a-input v-model:value="formEvent['date']"/>
        </a-form-item>
      </a-form>
    </a-modal>


  </div>

</template>

<script>
import {apiUrl} from "@/config/api";
import { notification } from 'ant-design-vue';
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment-timezone";
import { reactive } from 'vue';

export default {
  name: 'events_cluster',
  components: {
    AgGridVue,
  },
  props: ['keycloak'],
  data() {
    return {
      actionOptions: [
        {value:'START',label:'START'},
        {value:'NONE',label:'NONE'}
      ],
      formEvent: reactive({}),
      config: {
        rules: [{ type: 'string', required: true, message: 'Please select time!' }],
      },
      modalVisible: false,
      formEventAction: "",
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellClicked: this.updateEvent
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      events: [],
      columns: [
        {
          headerName: 'Time',
          field: 'start',
          sortable: true,
          cellRenderer: (data) => {
            return this.getDateFormated(data.value)
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'Action',
          field: 'action',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Created by',
          field: 'userName',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Date execution',
          field: 'date',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ]
    }
  },
  created() {
    this.refreshEvents()
  },
  methods: {
    refreshEvents() {
      const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + this.keycloak.token };
      fetch(apiUrl + "events", { headers })
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : await response.text()

            if (!response.ok) {
              const error = (data || response.statusText)
              return Promise.reject(error)
            }
            this.events = data
          })
          .catch(error => {
            this.errorNotification(error)
          })
    },
    getDateFormated(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'Time', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    getRowStyle: (params) => {
      switch (params.data.action) {
        case "START":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    updateEvent(event) {
      this.formEvent.id = event.data.id
      this.formEvent.time = this.getDateFormated(event.data.start)
      this.formEvent.action = event.data.action
      this.formEvent.date = event.data.date
      this.formEventAction = "Update"
      this.modalVisible = true
    },
    okModal() {
      const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
      if (!moment(this.formEvent.time, ["DD/MM/YYYY HH:mm"]).isValid())
        this.errorNotification("Invalid Time")
      const event = this.formEvent
      if (this.formEventAction === "Insert") {
        const body = {
          "action": event.action,
          "date": event.date,
          "start": moment.tz(event.time, 'DD/MM/YYYY HH:mm', resolvedOptions.timeZone).format()
        }
        this.eventRequest(this.formEvent, 'POST', body)
      }
      if (this.formEventAction === "Update") {
        const body = {
          "action": event.action,
          "id": event.id,
          "date": event.date,
          "start": moment.tz(event.time, 'DD/MM/YYYY HH:mm', resolvedOptions.timeZone).format()
        }
        this.eventRequest(this.formEvent, 'PUT', body)
      }

      this.modalVisible = false
    },
    logout() {
      this.keycloak.logout()
    },
    insertEvent(){
      this.formEvent.time = ""
      this.formEvent.action = "START"
      this.formEventAction = "Insert"
      this.modalVisible = true
    },
    eventRequest(event, method, body){
      const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + this.keycloak.token }
      fetch(apiUrl + "events", {
        method: method,
        body: JSON.stringify(body),
        headers: headers
      })
          .then(async response => {
            if (!response.ok) {
              const error = (await response.text() || response.statusText)
              return Promise.reject(error)
            }
            else
              this.refreshEvents()
          })
          .catch(error => {
            this.errorNotification(error)
          })
    }
  }

}
</script>

<style>

#app {
  margin: 60px 20px;
}

#table {
  margin-top: 20px;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

#logout {
  float: right;
}

.error-class {
  color: red;
}

</style>